import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import { ApolloClient } from '../../../api/graphql/client';
import { useApolloClient } from '../../../api/config';
import {
    ProductDiscoveryConversationMessageWithProducts,
    useMerchantIdToMerchantNameMap,
    useHandleScroll,
} from '../../../lib/productDiscovery/conversationMessages';
import { deviceWidthMeasurements } from '../../../style/size';
import { font } from '../../../style/text';
import { colors } from '../../../tokens/colors/colors';
import { CLICKABLE_ELEMENT_CSS_MIXIN, NO_SCROLLBAR_CSS_MIXIN } from '../../../style/styleConstants';
import GradientText from '../../../components/common/GradientText';
import { getLocalizedTexts } from '../../../Locales';
import { MerchantProductOfferCard, MerchantProductOfferPlaceholderCard } from '../common/MerchantProductOfferCard';
import { InputBar } from '../common/InputBar';
import { UserFeedback } from '../common/UserFeedback';
import { RewindArrows } from '../../../style/reactSvg/RewindArrows';
import { ScrollToBottomButton } from '../common/ScrollToBottomButton';
import { useViewportSize } from '../../../style/utils';

const logo = '/assets/images/logos/lightning-round.svg';
const crossCircle = '/assets/images/icons/cross-circle.svg';

function DesktopMessagesSection({
    messages,
    messagesListRef,
    conversationId,
    inputText,
    setInputText,
    inputTextRef,
    setIsInputBarFocused,
    sendMessage,
    isAssistantThinking,
    bottomPositionScrollButton,
    setBottomPositionScrollButton,
}: {
    messages: ProductDiscoveryConversationMessageWithProducts[];
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>;
    conversationId: string;
    inputText: string;
    setInputText: (value: string) => void;
    inputTextRef: React.RefObject<HTMLTextAreaElement>;
    setIsInputBarFocused: (value: boolean) => void;
    sendMessage: (suggestedMessage?: string) => void;
    isAssistantThinking: boolean;
    bottomPositionScrollButton: number;
    setBottomPositionScrollButton: (value: number) => void;
}) {
    const apolloClient = useApolloClient();
    const productSuggestionsListRef = React.useRef<HTMLDivElement | null>(null);
    const merchantIdToMerchantNameMap = useMerchantIdToMerchantNameMap({ apolloClient, messages });
    const [userFeedbackMap, setUserFeedbackMap] = React.useState<{ [id: string]: 'positive' | 'negative' | null }>({});
    const { selectedMessagePositionForProductOffers, setSelectedMessagePositionForProductOffers } =
        useSelectedMessagePositionForProductOffers(messages, isAssistantThinking);
    const { isAtBottom: isMessagesListAtBottom, onScroll: onScrollMessagesList } = useHandleScroll({
        listRef: messagesListRef,
        listContent: messages,
        shouldAutoScrollToBottom: true,
    });
    const { isAtBottom: isProductSuggestionsListAtBottom, onScroll: onScrollProductSuggestionsList } = useHandleScroll({
        listRef: productSuggestionsListRef,
        listContent: selectedMessagePositionForProductOffers,
    });
    const { width: viewportWidth } = useViewportSize();
    const renderTextMessage = getRenderTextMessage({
        apolloClient,
        conversationId,
        userFeedbackMap,
        setUserFeedbackMap,
        selectedMessagePositionForProductOffers,
        setSelectedMessagePositionForProductOffers,
    });
    return (
        <MessagesSectionContainer>
            <LeftContainer>
                <MessagesTextContainer ref={messagesListRef} onScroll={onScrollMessagesList}>
                    <MessagesTextInnerContainer>
                        {messages.map((message) => renderTextMessage({ item: message, numberOfMessages: messages.length }))}
                    </MessagesTextInnerContainer>
                    {!isMessagesListAtBottom ? (
                        <ScrollToBottomButton
                            {...{
                                listRef: messagesListRef,
                                bottomPositionScrollButton,
                            }}
                        />
                    ) : null}
                </MessagesTextContainer>
                <InputBar
                    {...{
                        inputText,
                        setInputText,
                        inputTextRef,
                        setIsInputBarFocused,
                        sendMessage,
                        isAssistantThinking,
                        setBottomPositionScrollButton,
                    }}
                />
            </LeftContainer>
            <MessagesProductSuggestionsContainer
                {...{ isPlaceholder: selectedMessagePositionForProductOffers === undefined }}
                ref={productSuggestionsListRef}
                onScroll={onScrollProductSuggestionsList}>
                {selectedMessagePositionForProductOffers ? (
                    <>
                        <SuggestedMerchantProductOffersContainer>
                            {messages[selectedMessagePositionForProductOffers]?.merchantProductOffers?.map((offer) => (
                                <MerchantProductOfferCard
                                    key={offer.merchantProductOfferId}
                                    {...{ offer, merchantIdToMerchantNameMap }}
                                />
                            ))}
                        </SuggestedMerchantProductOffersContainer>
                        {!isProductSuggestionsListAtBottom &&
                        messages[selectedMessagePositionForProductOffers]?.merchantProductOffers?.length ? (
                            <ScrollToBottomButton
                                {...{
                                    listRef: productSuggestionsListRef,
                                    bottomPositionScrollButton: 10,
                                }}
                            />
                        ) : null}
                    </>
                ) : (
                    <SuggestedMerchantProductOffersContainer {...{ isPlaceholder: true }}>
                        <MerchantProductOfferPlaceholderCards {...{ viewportWidth }} />
                    </SuggestedMerchantProductOffersContainer>
                )}
            </MessagesProductSuggestionsContainer>
        </MessagesSectionContainer>
    );
}

export default DesktopMessagesSection;

function useSelectedMessagePositionForProductOffers(
    messages: ProductDiscoveryConversationMessageWithProducts[],
    isAssistantThinking: boolean
) {
    const getInitialSelectedMessageId = () => {
        if (messages.length > 1 && messages[messages.length - 1]?.role === 'assistant') {
            return Number(messages[messages.length - 1].messagePosition);
        }
        return undefined;
    };
    const [selectedMessagePositionForProductOffers, setSelectedMessagePositionForProductOffers] = React.useState<
        number | undefined
    >(getInitialSelectedMessageId());
    React.useEffect(() => {
        if (messages.length > 1 && messages[messages.length - 1]?.role === 'assistant' && !isAssistantThinking)
            setSelectedMessagePositionForProductOffers(Number(messages[messages.length - 1].messagePosition));
        else if (isAssistantThinking) setSelectedMessagePositionForProductOffers(undefined);
    }, [messages, isAssistantThinking]);
    return { selectedMessagePositionForProductOffers, setSelectedMessagePositionForProductOffers };
}

function getRenderTextMessage({
    apolloClient,
    conversationId,
    userFeedbackMap,
    setUserFeedbackMap,
    selectedMessagePositionForProductOffers,
    setSelectedMessagePositionForProductOffers,
}: {
    apolloClient: ApolloClient | undefined;
    conversationId: string;
    userFeedbackMap: { [id: string]: 'positive' | 'negative' | null };
    setUserFeedbackMap: React.Dispatch<React.SetStateAction<{ [id: string]: 'positive' | 'negative' | null }>>;
    selectedMessagePositionForProductOffers: number | undefined;
    setSelectedMessagePositionForProductOffers: React.Dispatch<React.SetStateAction<number | undefined>>;
}): React.FC<{ item: ProductDiscoveryConversationMessageWithProducts; numberOfMessages: number }> {
    return ({ item: message, numberOfMessages }) => {
        if (message.role === 'user') return <UserMessage {...{ message }} key={message.messagePosition} />;
        return (
            <AssistantMessage
                {...{
                    message,
                    numberOfMessages,
                    apolloClient,
                    conversationId,
                    userFeedbackMap,
                    setUserFeedbackMap,
                    selectedMessagePositionForProductOffers,
                    setSelectedMessagePositionForProductOffers,
                }}
                key={message.messagePosition}
            />
        );
    };
}

function UserMessage({ message }: { message: ProductDiscoveryConversationMessageWithProducts }) {
    return (
        <UserMessageContainer {...{ isFirstMessage: message.messagePosition === '0' }}>
            <MessageUserText>{message.content}</MessageUserText>
        </UserMessageContainer>
    );
}

function AssistantMessage({
    message,
    numberOfMessages,
    apolloClient,
    conversationId,
    userFeedbackMap,
    setUserFeedbackMap,
    selectedMessagePositionForProductOffers,
    setSelectedMessagePositionForProductOffers,
}: {
    message: ProductDiscoveryConversationMessageWithProducts;
    numberOfMessages: number;
    apolloClient: ApolloClient | undefined;
    conversationId: string;
    userFeedbackMap: { [id: string]: 'positive' | 'negative' | null };
    setUserFeedbackMap: React.Dispatch<React.SetStateAction<{ [id: string]: 'positive' | 'negative' | null }>>;
    selectedMessagePositionForProductOffers: number | undefined;
    setSelectedMessagePositionForProductOffers: React.Dispatch<React.SetStateAction<number | undefined>>;
}) {
    const texts = getLocalizedTexts();
    const shouldShowFeedbackSurvey = !!message.merchantProductOffers?.length;
    return (
        <AssistantMessageContainer>
            <AssistantMessageTextContainer>
                <JokoLogoMessageImage src={logo} />
                {message.content ? (
                    <MessageAssistantText>
                        <FormattedMessage>{message.content}</FormattedMessage>
                    </MessageAssistantText>
                ) : (
                    // TODO: refactor to remove React Native components
                    <GradientText text={texts.productDiscovery.thinkingPlaceholder} fontSize={18} />
                )}
            </AssistantMessageTextContainer>
            {shouldShowFeedbackSurvey ? (
                <AssistantMessageIconsContainer>
                    <UserFeedback
                        {...{
                            client: apolloClient,
                            conversationId,
                            messageId: message.messagePosition,
                            userFeedbackMap,
                            setUserFeedbackMap,
                        }}
                    />
                    {Number(message.messagePosition) !== numberOfMessages - 1 ? (
                        selectedMessagePositionForProductOffers === Number(message.messagePosition) ? (
                            <RewindArrowsImageContainer
                                onClick={() => setSelectedMessagePositionForProductOffers(numberOfMessages - 1)}>
                                <CrossCircleImage src={crossCircle} />
                            </RewindArrowsImageContainer>
                        ) : (
                            <RewindArrowsImageContainer
                                onClick={() => setSelectedMessagePositionForProductOffers(Number(message.messagePosition))}>
                                <RewindArrows />
                            </RewindArrowsImageContainer>
                        )
                    ) : null}
                </AssistantMessageIconsContainer>
            ) : null}
        </AssistantMessageContainer>
    );
}

function MerchantProductOfferPlaceholderCards({ viewportWidth }: { viewportWidth: number }) {
    return (
        <>
            {Array.from({
                length: viewportWidth < 740 ? 2 : viewportWidth < 1024 ? 6 : 8,
            }).map((_, index) => (
                <MerchantProductOfferPlaceholderCard key={index} />
            ))}
        </>
    );
}

const MessagesSectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 40%;
    max-width: 40%;
    margin-left: 24px;
    margin-top: 24px;
    padding: 24px 24px 0px 24px;
    border-radius: 12px 12px 0px 0px;
    background-color: ${colors.background.default};

    @media (max-width: 1500px) {
        min-width: 480px;
        max-width: 480px;
    }

    @media (max-width: 900px) {
        min-width: 380px;
        max-width: 380px;
    }

    @media (max-width: 650px) {
        min-width: 300px;
        max-width: 300px;
    }
`;

const MessagesTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 24px;
    overflow-x: hidden;
    overflow-y: auto;

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

const MessagesTextInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
`;

const UserMessageContainer = styled.div<{ isFirstMessage: boolean }>`
    max-width: 70%;
    align-self: flex-end;
    padding: 16px 24px 16px 24px;
    margin-top: ${({ isFirstMessage }) => (isFirstMessage ? '0px' : '28px')};
    margin-bottom: 24px;
    border-radius: 12px;
    background-color: ${colors.background.light};
`;

const MessageUserText = styled.div`
    font-size: 18px;
    font-family: ${font.ambitSemiBold};
    line-height: 21px;
`;

const AssistantMessageContainer = styled.div`
    flex-direction: column;
    align-self: flex-start;
`;

const AssistantMessageIconsContainer = styled.div`
    display: flex;
    height: 20px;
    align-items: center;
    margin-top: 8px;
`;

const RewindArrowsImageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 8px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;

const AssistantMessageTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-width: calc(100vw - 32px);
    align-items: flex-start;
`;

const MessageAssistantText = styled.div`
    margin-top: 4px;
    font-size: 18px;
    font-family: ${font.ambitSemiBold};
    line-height: 25px;
`;

// ReactMarkdown wraps text content in <p> tags, which typically have a default margin applied by browsers.
// This is a workaround to remove the margin.
const FormattedMessage = styled(ReactMarkdown)`
    p {
        margin: 0;
    }
`;

const MessagesProductSuggestionsContainer = styled.div<{ isPlaceholder?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 24px;
    padding: 0 24px;
    overflow-y: ${({ isPlaceholder }) => (isPlaceholder ? 'hidden' : 'auto')};

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

const SuggestedMerchantProductOffersContainer = styled.div<{ isPlaceholder?: boolean }>`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, minmax(0, 1fr)); // Ensures there are exactly 4 columns, with each taking equal space
    gap: 12px 24px;
    justify-content: space-between;
    ${({ isPlaceholder }) => (isPlaceholder ? '' : 'justify-items: center')}
    margin-bottom: 12px;

    @media (max-width: ${deviceWidthMeasurements.xLarge}) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: ${deviceWidthMeasurements.large}) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: ${deviceWidthMeasurements.medium}) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
`;

const JokoLogoMessageImage = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 12px;
    flex-shrink: 0; // Prevent the logo from shrinking with the text
`;

const CrossCircleImage = styled.img`
    width: 18px;
    height: 18px;
    object-fit: contain;
`;
