import React from 'react';
import styled from 'styled-components';

import { ApolloClient } from '../../../api/graphql/client';
import { logUserEventUtil } from '../../../lib/events/userEvents';
import Thumb from '../../../style/reactSvg/Thumb';
import { CLICKABLE_ELEMENT_CSS_MIXIN } from '../../../style/styleConstants';
import { deviceWidthMeasurements } from '../../../style/size';

export function UserFeedback({
    client,
    conversationId,
    messageId,
    userFeedbackMap,
    setUserFeedbackMap,
}: {
    client: ApolloClient | undefined;
    conversationId: string;
    messageId: string;
    userFeedbackMap: { [id: string]: 'positive' | 'negative' | null };
    setUserFeedbackMap: React.Dispatch<React.SetStateAction<{ [id: string]: 'positive' | 'negative' | null }>>;
}) {
    const onClickPositiveFeedback = () => {
        const feedback = userFeedbackMap[messageId] === 'positive' ? null : 'positive';
        setUserFeedbackMap((prevFeedback) => ({
            ...prevFeedback,
            [messageId]: feedback,
        }));
        if (client)
            logUserEventUtil(client, {
                type: 'sentProductDiscoverySuggestionsFeedback',
                payload: { conversationId, messageId, feedbackType: feedback ? 'positive' : 'removed' },
            });
    };
    const onClickNegativeFeedback = () => {
        const feedback = userFeedbackMap[messageId] === 'negative' ? null : 'negative';
        setUserFeedbackMap((prevFeedback) => ({
            ...prevFeedback,
            [messageId]: feedback,
        }));
        if (client)
            logUserEventUtil(client, {
                type: 'sentProductDiscoverySuggestionsFeedback',
                payload: { conversationId, messageId, feedbackType: feedback ? 'negative' : 'removed' },
            });
    };
    return (
        <UserFeedbackContainer>
            <ThumbImageContainer style={{ marginRight: 7 }} onClick={onClickPositiveFeedback}>
                <Thumb isSelected={userFeedbackMap[messageId] === 'positive'} orientation="up" />
            </ThumbImageContainer>
            <ThumbImageContainer onClick={onClickNegativeFeedback}>
                <Thumb isSelected={userFeedbackMap[messageId] === 'negative'} orientation="down" />
            </ThumbImageContainer>
        </UserFeedbackContainer>
    );
}

const UserFeedbackContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 40px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-top: 16px;
        margin-left: 36px;
    }
`;

const ThumbImageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;
