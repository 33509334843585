import * as React from 'react';
import { StyleSheet, View, Text, Image, ScrollView } from 'react-native';
import { Svg, Circle, Path } from 'react-native-svg';

import { getLocalizedTexts, formatCurrencyAmount } from '../../../Locales';
import color from '../../../style/color';
import { font } from '../../../style/text';
import { useWindowSize } from '../../../style/utils';
import { HOME_SCREEN_WIDTH, NAVIGATION_BAR_HEIGHT } from '../../../style/size';
import { Reward, RewardType } from '../../../api/graphql/fragments/rewards';
import { useHandleIntercomWidgetDisplay } from '../../../lib/common/intercom';
import { Feature, useUserFeaturesMap } from '../../../lib/features/buildFeatureMap';
import { useRewardsByType, useViewRewardDetails } from '../../../lib/rewards/rewards';
import { useUserPointBalance, convertPointsToCurrencyAmount } from '../../../lib/points/points';
import HorizontalFlatList from '../../../components/common/HorizontalFlatList';
import { HoverableTouchableOpacity } from '../../../components/common/HoverableComponents';
import Footer from '../../../components/common/Footer';

function RewardsPage() {
    const size = useWindowSize();
    const rewardsByType = useRewardsByType();
    const userPointBalance = useUserPointBalance();
    const userFeatureMap = useUserFeaturesMap();
    useHandleIntercomWidgetDisplay();
    return (
        <ScrollView style={{ width: size.width, height: (size.height || 0) - NAVIGATION_BAR_HEIGHT }}>
            <View style={styles.container}>
                <BankTransfers bankTransfers={rewardsByType[RewardType.bankTransfer]} {...{ userPointBalance }} />
                {userFeatureMap?.[Feature.giftCardsAtRedemption] ? (
                    <GiftCards giftCards={rewardsByType[RewardType.giftCard]} {...{ userPointBalance }} />
                ) : null}
                <Donations donations={rewardsByType[RewardType.donation]} {...{ userPointBalance }} />
                <Footer />
            </View>
        </ScrollView>
    );
}

export default RewardsPage;

function BankTransfers({
    bankTransfers,
    userPointBalance,
}: {
    bankTransfers: Reward[] | undefined;
    userPointBalance: number | undefined;
}) {
    const texts = getLocalizedTexts().home.rewards.rewardPage.bankTransfers;
    const bankTransfer: Reward | undefined = React.useMemo(() => bankTransfers?.[0], [bankTransfers]);
    const viewRewardDetails = useViewRewardDetails();
    if (!bankTransfer || !bankTransfer.minPoints) return null;
    return (
        <HoverableTouchableOpacity
            style={styles.containerBankTransfer}
            onPress={() => viewRewardDetails(bankTransfer)}
            hoveredStyle={{ opacity: 0.8 }}>
            <View style={styles.containerBankTransferImage}>
                <Image source={{ uri: bankTransfer.card?.imageURL || '' }} style={styles.imageBankTransfer} />
            </View>
            <View style={styles.containerBankTransferTexts}>
                <Text style={styles.textRewardItemTitle}>{bankTransfer.card?.title || texts.title}</Text>
                <Text style={styles.textRewardItemSubtitle}>{texts.subtitle}</Text>
                <View style={styles.containerBankTransferAmount}>
                    <Text
                        style={
                            userPointBalance && bankTransfer.minPoints && userPointBalance < bankTransfer.minPoints
                                ? styles.textLockedRewardItemAmount
                                : styles.textRewardItemAmount
                        }>
                        {texts.amount({
                            minAmount: convertPointsToCurrencyAmount(bankTransfer.minPoints),
                        })}
                    </Text>
                </View>
            </View>
        </HoverableTouchableOpacity>
    );
}

function GiftCards({
    giftCards,
    userPointBalance,
}: {
    giftCards: Reward[] | undefined;
    userPointBalance: number | undefined;
}) {
    const texts = getLocalizedTexts().home.rewards.rewardPage.giftCards;
    if (!giftCards || !giftCards.length) return null;
    return (
        <RewardSection
            sectionTitle={texts.sectionTitle}
            rewards={giftCards}
            itemTitle={(reward) => reward.card?.title || reward.screen?.title || ''}
            itemSubtitle={(reward) =>
                texts.itemSubtitle({
                    value: convertPointsToCurrencyAmount(reward.points || 0),
                })
            }
            itemImageURL={(reward) => reward.card?.imageURL || ''}
            {...{ userPointBalance }}
            numberOfRows={2}
        />
    );
}

function Donations({
    donations,
    userPointBalance,
}: {
    donations: Reward[] | undefined;
    userPointBalance: number | undefined;
}) {
    const texts = getLocalizedTexts().home.rewards.rewardPage.donations;
    if (!donations || !donations.length) return null;
    return (
        <RewardSection
            sectionTitle={texts.sectionTitle}
            rewards={donations}
            itemTitle={(reward) => reward.card?.title || reward.screen?.title || ''}
            itemSubtitle={(reward) =>
                texts.itemSubtitle({
                    value: convertPointsToCurrencyAmount(reward.points || 0),
                })
            }
            itemImageURL={(reward) => reward.screen?.imageURL || ''}
            {...{ userPointBalance }}
            numberOfRows={1}
        />
    );
}

function RewardSection({
    rewards,
    sectionTitle,
    itemTitle,
    itemSubtitle,
    itemImageURL,
    userPointBalance,
    numberOfRows,
}: {
    rewards: Reward[];
    sectionTitle: string;
    itemTitle: (reward: Reward) => string;
    itemSubtitle: (reward: Reward) => string;
    itemImageURL: (reward: Reward) => string;
    userPointBalance: number | undefined;
    numberOfRows: number;
}) {
    return (
        <View style={styles.containerRewardSection}>
            <View style={styles.containerRewardSectionTitle}>
                <Text style={styles.textRewardSectionTitle}>{sectionTitle}</Text>
            </View>
            <HorizontalFlatList
                data={rewards}
                numberOfRows={numberOfRows}
                renderItem={(reward) => (
                    <RewardItem
                        key={reward.rewardId}
                        {...{ reward, itemTitle, itemSubtitle, itemImageURL, userPointBalance }}
                    />
                )}
                initialNumberOfColumnsToDisplay={3}
            />
        </View>
    );
}

function RewardItem({
    reward,
    itemTitle,
    itemSubtitle,
    itemImageURL,
    userPointBalance,
}: {
    reward: Reward;
    itemTitle: (reward: Reward) => string;
    itemSubtitle: (reward: Reward) => string;
    itemImageURL: (reward: Reward) => string;
    userPointBalance: number | undefined;
}) {
    const viewRewardDetails = useViewRewardDetails();
    const isLocked: boolean = userPointBalance !== undefined && !!reward.points && userPointBalance < reward.points;
    const lockedProgress: number | undefined =
        userPointBalance !== undefined && !!reward.points ? Math.min(userPointBalance / reward.points, 1) : undefined;
    if (!reward.points) return null;
    return (
        <HoverableTouchableOpacity
            style={styles.containerRewardItem}
            onPress={() => viewRewardDetails(reward)}
            hoveredStyle={{ opacity: 0.8 }}>
            <Image source={{ uri: itemImageURL(reward) }} style={styles.imageRewardItem} />
            {lockedProgress !== undefined && lockedProgress < 1 ? (
                <View style={styles.containerRewardItemProgressIndicator}>
                    <ProgressIndicator progress={lockedProgress} />
                </View>
            ) : null}
            <View style={styles.containerRewardItemTexts}>
                <Text style={styles.textRewardItemTitle}>{itemTitle(reward)}</Text>
                <Text style={styles.textRewardItemSubtitle}>{itemSubtitle(reward)}</Text>
                <View style={styles.containerRewardItemAmount}>
                    <Text style={isLocked ? styles.textLockedRewardItemAmount : styles.textRewardItemAmount}>
                        {formatCurrencyAmount(convertPointsToCurrencyAmount(reward.points))}
                    </Text>
                </View>
            </View>
        </HoverableTouchableOpacity>
    );
}

function ProgressIndicator({ progress }: { progress: number }) {
    const size = 16;
    const strokeWidth = 3;
    const radius = (size - strokeWidth) / 2;
    return (
        <Svg width={size} height={size}>
            <Circle
                {...{ strokeWidth }}
                stroke={'rgba(255,255,255,0.25)'}
                fill="none"
                cx={size / 2}
                cy={size / 2}
                r={radius}
            />
            <Path
                {...{ strokeWidth }}
                stroke={color.white}
                fill="none"
                d={getEllipsePath(size / 2, size / 2, radius, radius, -Math.PI / 2, progress * 2 * Math.PI, 0)}
            />
        </Svg>
    );
}

function getEllipsePath(
    centerX: number,
    centerY: number,
    radiusX: number,
    radiusY: number,
    startAngleRad: number,
    sweepAngleRad: number,
    rotationAngleRad: number
): string {
    const { cos, sin, PI } = Math;
    const tau = 2 * PI;
    const multiply = ([a, b, c, d]: [number, number, number, number], x: number, y: number): [number, number] => [
        a * x + b * y,
        c * x + d * y,
    ];
    const rotate = (x: number): [number, number, number, number] => [cos(x), -sin(x), sin(x), cos(x)];
    const add = ([a1, a2]: [number, number], b1: number, b2: number) => [a1 + b1, a2 + b2];
    sweepAngleRad = sweepAngleRad % tau;
    const rotationMatrix = rotate(rotationAngleRad);
    const [startX, startY] = add(
        multiply(rotationMatrix, radiusX * cos(startAngleRad), radiusY * sin(startAngleRad)),
        centerX,
        centerY
    );
    const [endX, endY] = add(
        multiply(rotationMatrix, radiusX * cos(startAngleRad + sweepAngleRad), radiusY * sin(startAngleRad + sweepAngleRad)),
        centerX,
        centerY
    );
    const fA = sweepAngleRad > PI ? 1 : 0;
    const fS = sweepAngleRad > 0 ? 1 : 0;
    const path = ['M', startX, startY, 'A', radiusX, radiusY, (rotationAngleRad / tau) * 360, fA, fS, endX, endY];
    return path.join(' ');
}

const REWARD_ITEM_IMAGE_WIDTH = Math.floor((HOME_SCREEN_WIDTH - 40) / 3);
const REWARD_ITEM_IMAGE_HEIGHT = REWARD_ITEM_IMAGE_WIDTH / 1.5;

const styles = StyleSheet.create({
    container: {
        width: HOME_SCREEN_WIDTH,
        paddingTop: 20,
        alignSelf: 'center',
    },
    containerBankTransfer: {
        paddingBottom: 20,
    },
    containerBankTransferImage: {
        flexDirection: 'row',
        height: 173,
    },
    containerBankTransferTexts: {
        marginTop: 10,
    },
    containerBankTransferAmount: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
    },
    containerRewardSection: {
        paddingBottom: 20,
    },
    containerRewardSectionTitle: {
        paddingTop: 20,
        paddingBottom: 16,
        borderTopColor: color.linkWaterLight,
        borderTopWidth: 2,
    },
    containerRewardItem: {
        width: REWARD_ITEM_IMAGE_WIDTH,
    },
    containerRewardItemProgressIndicator: {
        position: 'absolute',
        left: REWARD_ITEM_IMAGE_WIDTH - 26,
        top: REWARD_ITEM_IMAGE_HEIGHT - 26,
    },
    containerRewardItemTexts: {
        marginTop: 8,
    },
    containerRewardItemAmount: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 4,
    },
    textRewardItemTitle: {
        fontFamily: font.ambitBold,
        fontSize: 16,
        color: color.black,
    },
    textRewardItemSubtitle: {
        fontFamily: font.ambitBold,
        fontSize: 12,
        color: color.grayLight,
    },
    textRewardItemAmount: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.emerald,
    },
    textLockedRewardItemAmount: {
        fontFamily: font.ambitBlack,
        fontSize: 14,
        color: color.frenchGray,
    },
    textRewardSectionTitle: {
        fontFamily: font.ambitBlack,
        fontSize: 20,
        color: color.black,
    },
    imageBankTransfer: {
        flex: 1,
        borderRadius: 8,
        resizeMode: 'contain',
        backgroundColor: color.tranquil,
    },
    imageRewardItem: {
        height: REWARD_ITEM_IMAGE_HEIGHT,
        width: REWARD_ITEM_IMAGE_WIDTH,
        borderRadius: 8,
        backgroundColor: color.linkWaterLight,
    },
});
