import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import { getLocalizedTexts } from '../../../Locales';
import { useApolloClient } from '../../../api/config';
import { ApolloClient } from '../../../api/graphql/client';
import {
    MerchantIdToMerchantNameMap,
    MerchantProductOffer,
    ProductDiscoveryConversationMessageWithProducts,
    useMerchantIdToMerchantNameMap,
    useHandleScroll,
} from '../../../lib/productDiscovery/conversationMessages';
import { font } from '../../../style/text';
import { colors } from '../../../tokens/colors/colors';
import { NO_SCROLLBAR_CSS_MIXIN } from '../../../style/styleConstants';
import GradientText from '../../../components/common/GradientText';
import { UserFeedback } from '../common/UserFeedback';
import { MerchantProductOfferCard } from '../common/MerchantProductOfferCard';
import { ScrollToBottomButton } from '../common/ScrollToBottomButton';

const logo = '/assets/images/logos/lightning-round.svg';

export function MobileMessagesSection({
    messages,
    messagesListRef,
    conversationId,
    bottomPositionScrollButton,
}: {
    messages: ProductDiscoveryConversationMessageWithProducts[];
    messagesListRef: React.MutableRefObject<HTMLDivElement | null>;
    conversationId: string;
    bottomPositionScrollButton: number;
}) {
    const apolloClient = useApolloClient();
    const merchantIdToMerchantNameMap = useMerchantIdToMerchantNameMap({ apolloClient, messages });
    const [userFeedbackMap, setUserFeedbackMap] = React.useState<{ [id: string]: 'positive' | 'negative' | null }>({});
    const { isAtBottom, onScroll } = useHandleScroll({
        listRef: messagesListRef,
        listContent: messages,
        shouldAutoScrollToBottom: true,
        scrollBehavior: 'auto',
    });
    const renderMessage = getRenderMessage({
        apolloClient,
        merchantIdToMerchantNameMap,
        conversationId,
        userFeedbackMap,
        setUserFeedbackMap,
    });
    return (
        <>
            <MessagesContainer ref={messagesListRef} onScroll={onScroll}>
                <MessagesInnerContainer>
                    {messages.map((message) => renderMessage({ item: message }))}
                </MessagesInnerContainer>
            </MessagesContainer>
            {!isAtBottom ? <ScrollToBottomButton {...{ listRef: messagesListRef, bottomPositionScrollButton }} /> : null}
        </>
    );
}

function getRenderMessage({
    apolloClient,
    merchantIdToMerchantNameMap,
    conversationId,
    userFeedbackMap,
    setUserFeedbackMap,
}: {
    apolloClient: ApolloClient | undefined;
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
    conversationId: string;
    userFeedbackMap: { [id: string]: 'positive' | 'negative' | null };
    setUserFeedbackMap: React.Dispatch<React.SetStateAction<{ [id: string]: 'positive' | 'negative' | null }>>;
}): React.FC<{ item: ProductDiscoveryConversationMessageWithProducts }> {
    return ({ item: message }) => {
        if (message.role === 'user') return <UserMessage {...{ message }} key={message.messagePosition} />;
        return (
            <AssistantMessage
                {...{
                    message,
                    apolloClient,
                    merchantIdToMerchantNameMap,
                    conversationId,
                    userFeedbackMap,
                    setUserFeedbackMap,
                }}
                key={message.messagePosition}
            />
        );
    };
}

function UserMessage({ message }: { message: ProductDiscoveryConversationMessageWithProducts }) {
    return (
        <UserMessageContainer {...{ isFirstMessage: message.messagePosition === '0' }}>
            <MessageUserText>{message.content}</MessageUserText>
        </UserMessageContainer>
    );
}

function AssistantMessage({
    message,
    apolloClient,
    merchantIdToMerchantNameMap,
    conversationId,
    userFeedbackMap,
    setUserFeedbackMap,
}: {
    message: ProductDiscoveryConversationMessageWithProducts;
    apolloClient: ApolloClient | undefined;
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
    conversationId: string;
    userFeedbackMap: { [id: string]: 'positive' | 'negative' | null };
    setUserFeedbackMap: React.Dispatch<React.SetStateAction<{ [id: string]: 'positive' | 'negative' | null }>>;
}) {
    const texts = getLocalizedTexts();
    return (
        <AssistantMessageContainer>
            <AssistantMessageTextContainer>
                <JokoLogoMessageImage src={logo} />
                {message.content ? (
                    <MessageAssistantText>
                        <FormattedMessage>{message.content}</FormattedMessage>
                    </MessageAssistantText>
                ) : (
                    // TODO: refactor to remove React Native components
                    <GradientText text={texts.productDiscovery.thinkingPlaceholder} fontSize={16} />
                )}
            </AssistantMessageTextContainer>
            {message.merchantProductOffers?.length ? (
                <SuggestedMerchantProductOffers
                    {...{ offers: message.merchantProductOffers, merchantIdToMerchantNameMap }}
                />
            ) : null}
            {message.merchantProductOffers?.length && apolloClient ? (
                <UserFeedback
                    {...{
                        client: apolloClient,
                        conversationId,
                        messageId: message.messagePosition,
                        userFeedbackMap,
                        setUserFeedbackMap,
                    }}
                />
            ) : null}
        </AssistantMessageContainer>
    );
}

function SuggestedMerchantProductOffers({
    offers,
    merchantIdToMerchantNameMap,
}: {
    offers: MerchantProductOffer[];
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
}) {
    return (
        <SuggestedMerchantProductOffersContainer>
            {offers.map((offer) => (
                <MerchantProductOfferCard key={offer.merchantProductOfferId} {...{ offer, merchantIdToMerchantNameMap }} />
            ))}
        </SuggestedMerchantProductOffersContainer>
    );
}

const MessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    width: calc(100% - 32px);
    margin-top: 16px;
    margin-bottom: 18px;
    padding: 0 16px;
    overflow-x: hidden;
    overflow-y: auto;

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

const MessagesInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const UserMessageContainer = styled.div<{ isFirstMessage: boolean }>`
    max-width: 256px;
    align-self: flex-end;
    padding: 16px 24px 16px 24px;
    margin-top: ${({ isFirstMessage }) => (isFirstMessage ? '0px' : '24px')};
    margin-bottom: 24px;
    border-radius: 12px;
    background-color: ${colors.background.light};
`;

const AssistantMessageContainer = styled.div`
    flex-direction: column;
    align-self: flex-start;
`;

const AssistantMessageTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-width: calc(100vw - 32px);
    align-items: flex-start;
`;

const MessageUserText = styled.div`
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    line-height: 21px;
`;

const MessageAssistantText = styled.div`
    margin-top: 4px;
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    line-height: 22px;
`;

// ReactMarkdown wraps text content in <p> tags, which typically have a default margin applied by browsers.
// This is a workaround to remove the margin.
const FormattedMessage = styled(ReactMarkdown)`
    p {
        margin: 0;
    }
`;

const SuggestedMerchantProductOffersContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    width: calc(100vw - 56px - 16px);
    gap: 8px;
    padding-left: 56px;
    padding-right: 16px;
    margin-top: 24px;
    margin-bottom: 18px;
    margin-left: 44px;
    transform: translateX(-60px); /* Adjust back to align to viewport edges */
    overflow-x: auto;

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

const JokoLogoMessageImage = styled.img`
    width: 28px;
    height: 28px;
    margin-right: 12px;
    flex-shrink: 0; // Prevent the logo from shrinking with the text
`;
